<template>
  <v-app>
    <v-main>
      <AppBar v-if="shouldRenderAppBar" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
export default {
  name: "App",
  components: {
    AppBar,
  },
  computed: {
    shouldRenderAppBar() {
      return this.$route.name && this.$route.name != "login";
    },
  },
};
</script>

<style>
#app {
  background-color: var(--v-background-base);
  font-family: "Inter", sans-serif;
  line-height: 1.5;
}

.header-dialog-title {
  background-color: rgba(94, 86, 105, 0.04);
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>
