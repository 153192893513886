import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/lib/locale/pt";
import HomeIcon from "../icons/HomeIcon.vue";
import ShopIcon from "../icons/ShopIcon.vue";
import UsersGroupIcon from "../icons/UsersGroupIcon.vue";
import StoreIcon from "../icons/StoreIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#38B6FF",
        secondary: "#6c757d",
        white: "#FEFEFE",
        background: "#f0f0f5",
      },
    },
    options: {
      customProperties: true,
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    values: {
      home: {
        component: HomeIcon
      },
      shop: {
        component: ShopIcon
      },
      usersGroup: {
        component: UsersGroupIcon
      },
      store: {
        component: StoreIcon
      }
    }
  },
});