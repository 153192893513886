<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83325 17.3333C5.37492 17.3333 4.9827 17.1703 4.65658 16.8442C4.32992 16.5175 4.16659 16.125 4.16659 15.6667C4.16659 15.2083 4.32992 14.8158 4.65658 14.4892C4.9827 14.163 5.37492 14 5.83325 14C6.29158 14 6.68381 14.163 7.00992 14.4892C7.33659 14.8158 7.49992 15.2083 7.49992 15.6667C7.49992 16.125 7.33659 16.5175 7.00992 16.8442C6.68381 17.1703 6.29158 17.3333 5.83325 17.3333ZM14.1666 17.3333C13.7083 17.3333 13.316 17.1703 12.9899 16.8442C12.6633 16.5175 12.4999 16.125 12.4999 15.6667C12.4999 15.2083 12.6633 14.8158 12.9899 14.4892C13.316 14.163 13.7083 14 14.1666 14C14.6249 14 15.0174 14.163 15.3441 14.4892C15.6702 14.8158 15.8333 15.2083 15.8333 15.6667C15.8333 16.125 15.6702 16.5175 15.3441 16.8442C15.0174 17.1703 14.6249 17.3333 14.1666 17.3333ZM5.12492 3.99999L7.12492 8.16666H12.9583L15.2499 3.99999H5.12492ZM4.33325 2.33332H16.6249C16.9999 2.33332 17.2569 2.47221 17.3958 2.74999C17.5346 3.02777 17.5277 3.31943 17.3749 3.62499L14.4166 8.95832C14.2638 9.2361 14.0624 9.45138 13.8124 9.60416C13.5624 9.75693 13.2777 9.83332 12.9583 9.83332H6.74992L5.83325 11.5H15.8333V13.1667H5.83325C5.20825 13.1667 4.73603 12.8922 4.41659 12.3433C4.09714 11.795 4.08325 11.25 4.37492 10.7083L5.49992 8.66666L2.49992 2.33332H0.833252V0.666656H3.54159L4.33325 2.33332Z"
      fill="#A9A9A9"
    />
  </svg>
</template>

<script>
export default {
  name: "ShopIcon",
};
</script>
