<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33337 8.79811C1.33337 7.52908 1.33337 6.89456 1.59477 6.33682C1.85616 5.77908 2.34706 5.36614 3.32885 4.54027L4.28123 3.73914C6.05582 2.24638 6.94311 1.5 8.00004 1.5C9.05697 1.5 9.94426 2.24638 11.7188 3.73914L12.6712 4.54027C13.653 5.36614 14.1439 5.77908 14.4053 6.33682C14.6667 6.89456 14.6667 7.52908 14.6667 8.79811V12.7614C14.6667 14.5238 14.6667 15.405 14.1088 15.9525C13.5509 16.5 12.653 16.5 10.8572 16.5H5.1429C3.34707 16.5 2.44916 16.5 1.89127 15.9525C1.33337 15.405 1.33337 14.5238 1.33337 12.7614V8.79811Z"
      stroke="#A9A9A9"
      stroke-width="2"
    />
    <path
      d="M10.5 15.5V10.5C10.5 9.94772 10.0523 9.5 9.5 9.5H6.5C5.94772 9.5 5.5 9.94772 5.5 10.5V15.5"
      stroke="#A9A9A9"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "HomeIcon",
};
</script>
