import axios from "axios";
import router from "../router/index"

const api = () => {
  const defaultOptions = {
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (!config.headers) config.headers = {};

    config.headers.Authorization = token ? `Bearer ${token}` : "";

    return config;
  });

  instance.interceptors.response.use((response) => {
    return response
  }, error => {
    if (router.currentRoute.name != 'login' && error.response.status === 401) {
      router.push({ name: "login" })
      localStorage.removeItem("user")
      localStorage.removeItem("token")
    }
    return Promise.reject(error);
  });

  return instance;
};

export default api();