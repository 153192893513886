import { checkToken } from '@/services/AuthService';
import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminParent from  "../views/admin/AdminParent.vue"
import StoreParent from  "../views/store/StoreParent.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: '/store',
    component: StoreParent,
    meta: {
      requiresAuth: true,
      roles: ['ROLE_CUSTOMER']
    },
    children: [
      {
        path: "orders",
        name: "store-orders",
        component: () => import("../views/store/PurchaseOrderView.vue")
      },
      {
        path: "customers",
        name: "store-customers",
        component: () => import("../views/store/CustomerView.vue")
      },
    ]
  },
  {
    path: "/admin",
    component: AdminParent,
    meta: {
      requiresAuth: true,
      roles: ['ROLE_ADMIN']
    },
    children: [
      {
        path: "users",
        name: "admin-users",
        component: () => import("../views/admin/UserView.vue")
      },
      {
        path: "products",
        name: "admin-products",
        component: () => import("../views/admin/ProductView.vue")
      },
      {
        path: "discountCoupons",
        name: "admin-discount-coupons",
        component: () => import("../views/admin/DiscountCouponView.vue")
      },
      {
        path: "priceLists",
        name: "admin-price-lists",
        component: () => import("../views/admin/PriceListView.vue")
      }
    ]
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: "/",
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    checkToken()
      .then(() => next())
      .catch(() => next({ name: 'login' }))
  } else {
    if (!to.name) next({ name: 'login' })
    if (to.name == 'login' && localStorage.getItem('token')) {
      next({ name: 'home' })
    }
    next()
  }
})

export default router