<template>
  <div>
    <v-app-bar app flat color="white">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-spacer />
      <strong> {{ welcomeMessage }} </strong>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item-content>
          <v-list-item class="d-flex justify-center">
            <v-img
              contain
              max-height="200"
              max-width="200"
              src="../assets/logo.png"
            />
          </v-list-item>
        </v-list-item-content>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="item in menuItemsAllowed"
            :key="item.title"
            link
            :to="{ name: item.to }"
            exact
          >
            <v-list-item-icon>
              <v-icon> {{ item.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-title> {{ item.title }} </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <v-list nav dense>
          <v-list-item @click="handleLogout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { UserType } from "@/enums/UserType";
import { getCurrentUser } from "@/utils/user";

export default {
  data: () => ({
    welcomeMessage: "Bem-vindo!",
    user: null,
    drawer: false,
    allMenuItems: [
      {
        title: "Home",
        icon: "mdi-home-outline",
        to: "home",
      },
      {
        title: "Pedidos",
        icon: "mdi-cart-outline",
        to: "store-orders",
        requiredTypes: [
          UserType.ADMINISTRADOR,
          UserType.CLIENTE,
          UserType.REPRESENTANTE,
        ],
      },
      {
        title: "Clientes",
        icon: "mdi-store-outline",
        to: "store-customers",
        requiredTypes: [UserType.ADMINISTRADOR, UserType.REPRESENTANTE],
      },
      {
        title: "Usuários",
        icon: "mdi-account-multiple-outline",
        to: "admin-users",
        requiredTypes: [UserType.ADMINISTRADOR],
      },
      {
        title: "Produtos",
        icon: "mdi-view-grid-plus-outline",
        to: "admin-products",
        requiredTypes: [UserType.ADMINISTRADOR],
      },      
      {
        title: "Tabelas de preço",
        icon: "mdi-currency-usd",
        to: "admin-price-lists",
        requiredTypes: [UserType.ADMINISTRADOR],
      },
      {
        title: "Cupons de desconto",
        icon: "mdi-ticket-percent-outline",
        to: "admin-discount-coupons",
        requiredTypes: [UserType.ADMINISTRADOR],
      }
    ],
    menuItemsAllowed: [],
  }),

  created() {
    this.drawer = this.$vuetify.breakpoint.name !== "xs";
    this.user = getCurrentUser();

    if (this.user) {
      this.welcomeMessage = `Bem-vindo, ${this.user.name}!`;
      this.getMenuItems();
    } else this.handleLogout();
  },

  methods: {
    handleLogout() {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      this.$router.push("/login");
    },

    getMenuItems() {
      this.menuItemsAllowed = this.allMenuItems.filter((menuItemAllowed) => {
        if (menuItemAllowed.requiredTypes == null) return true;
        return menuItemAllowed.requiredTypes.includes(this.user.userType);
      });
    },
  },
};
</script>

<style scoped>
.v-list--dense .v-list-item .v-list-item__title {
  color: #a9a9a9;
  font-weight: 400;
  font-size: 0.875rem;
}

.v-list--dense .v-list-item--active .v-list-item__title,
.v-list--dense .v-list-item--active .v-list-item__icon {
  color: #38b6ff;
}
</style>
